<template>
  <div id="staticListPage">
    <!-- Header -->
    <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
      <div class="bg-gray-50 border-b border-gray-200 px-3 py-2 leading-7 text-md">
        <span class="font-bold leading-8">Static Lists</span>
        <div class="float-right">
          <router-link :to="{ name: 'CustomersUpload' }">
            <el-button type="primary" size="small"> Create Static List </el-button>
          </router-link>
        </div>
      </div>

      <!-- Header With Filters -->
      <div class="bg-white">
        <!-- Loader -->
        <div class="w-full" v-if="fetchingData" v-loading="true" style="height: 500px"></div>

        <template v-else>
          <!-- Segment List -->
          <template v-if="staticList && staticList.length > 0">
            <div class="px-3 py-2.5 text-lg" :class="{ 'border-b': index != staticList.length - 1 }" v-for="(staticObj, index) in staticList" :key="index">
              <router-link :to="getStaticListLink(staticObj.id)">
                <el-button style="padding: 3px 0px" type="text"> {{ staticObj.name }}</el-button>
              </router-link>
            </div>
          </template>

          <!-- No Data -->
          <div class="bg-white text-center pb-5" v-else>
            <div class="pt-20 pb-4 text-gray-500 text-base">No Static Lists Found</div>

            <router-link :to="{ name: 'CustomersUpload' }">
              <el-button class="createSegmentButton" type="primary"> Create Static List </el-button>
            </router-link>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="./staticList.scss"></style>

<script>
import liveSegmentListComponent from './staticListComponent';
export default liveSegmentListComponent;
</script>
