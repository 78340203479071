import staticSegment from '@/services/staticSegment';

export default {
  name: 'StaticList',
  data() {
    return {
      fetchingData: false,
      staticList: []
    };
  },

  methods: {
    getStaticListLink(segmentId) {
      return '/segment/static-list/' + segmentId;
    },

    async fetchStaticLists() {
      try {
        this.fetchingData = true;
        let result = await staticSegment.getAllStaticSegments(false);
        this.staticList = result.data;
        this.fetchingData = false;
      } catch (e) {
        this.reportError(e);
        this.errorToast('Failed to fetch static lists! Plesase contact support.');
      }
    }
  },

  mounted() {
    this.fetchStaticLists();
  }
};
